import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { Typography } from "src/components";
import {
  SessionContainer,
  SessionDuration,
  SkipButton,
  WelcomeWrapper,
  MatchingWrapper,
  WidgetWrapper,
  Wrapper,
} from "./styles";
import { Clock } from "src/assets/icons";
import theme from "src/theme";
import { useMediaQuery } from "@mui/material";
import useUpdateStatus from "../../utils/useUpdateStatus";
import { useGetOnboardingNextStep } from "../../utils/useOnboardingSteps";
import { useGetMemberQuery } from "src/api/main";
import { useGetOnboardingStatusQueryResolver } from "src/api/resolvers";
import { useGetFirstSessionLink } from "src/utils/getFirstSessionLink";
import useIsMatchingPlanCategory from "src/hooks/useIsMatchingPlan";

const BookSession = () => {
  const mobileView = useMediaQuery(theme.breakpoints.down("lg"));
  const { data: onboardingStatus } = useGetOnboardingStatusQueryResolver();
  const updateOnboardingStatus = useUpdateStatus();
  const redirectToNextStep = useGetOnboardingNextStep();

  const isMatchingTier = useIsMatchingPlanCategory();
  const sessionType = isMatchingTier ? "matching" : "welcome";

  useEffect(() => {
    if (
      (sessionType === "welcome" && (onboardingStatus?.welcome_session || onboardingStatus?.welcome_session_skipped)) ||
      (sessionType === "matching" && (onboardingStatus?.matching_session || onboardingStatus?.matching_session_skipped))
    ) {
      redirectToNextStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingStatus]);

  useCalendlyEventListener({
    onEventScheduled: () =>
      updateOnboardingStatus({
        [`${sessionType}_session_skipped`]: false,
        [`${sessionType}_session`]: true,
      }),
  });

  const skipSession = () => {
    updateOnboardingStatus({
      [`${sessionType}_session_skipped`]: true,
      [`${sessionType}_session`]: false,
    });
  };

  return (
    <Layout
      title="Book your first session"
      subtitle="Schedule your first meeting with your relationship expert and start learning the skills you need to create the relationship you want."
      LeftSide={<LeftSide onSkipClick={skipSession} sessionType={sessionType} />}
      RightSide={mobileView ? <></> : <SessionComponent sessionType={sessionType} />}
    />
  );
};

const LeftSide = ({
  onSkipClick,
  sessionType,
}: {
  onSkipClick: () => void;
  sessionType: "welcome" | "matching";
}) => {
  const mobileView = useMediaQuery(theme.breakpoints.down("lg"));
  const WrapperComponent = sessionType === "welcome" ? WelcomeWrapper : MatchingWrapper;

  return (
    <Wrapper>
      <SessionDuration>
        <Clock />
        <Typography color="black">30 min</Typography>
      </SessionDuration>
      {mobileView && (
        <WrapperComponent>
          <SessionComponent sessionType={sessionType} />
        </WrapperComponent>
      )}
      <SkipButton id={`onboarding_skip-${sessionType}-session`} onClick={onSkipClick}>
        <Typography variant="button">Schedule my session later</Typography>
      </SkipButton>
    </Wrapper>
  );
};

const SessionComponent = ({
  sessionType,
}: {
  sessionType: "welcome" | "matching";
}) => {
  const sessionLink = useGetFirstSessionLink(sessionType);

  const { data: member } = useGetMemberQuery();
  const [extended, setExtended] = useState(false);

  useCalendlyEventListener({
    onDateAndTimeSelected: () => setExtended(true),
    onEventTypeViewed: () => setExtended(false),
  });

  return (
    <SessionContainer>
      <WidgetWrapper
        sx={{
          ".calendly-inline-widget": {
            height: `${extended ? 955 : 630}px !important`,
            [theme.breakpoints.down("lg")]: {
              height: `${extended ? 1050 : 600}px !important`,
            },
          },
        }}
      >
        <InlineWidget
          url={sessionLink}
          pageSettings={{ hideEventTypeDetails: true }}
          prefill={{
            name: `${member?.first_name} ${member?.last_name}`,
            email: member?.email,
          }}
        />
      </WidgetWrapper>
    </SessionContainer>
  );
};

export default BookSession;
